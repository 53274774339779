(function loginModule(global) {
    const Cookies = global.Cookies;
    const doc = global.document;
    const loc = global.location;
    const isReturnUser = Cookies.get('return') === '1';
    const form = doc.querySelector('.form');
    const passwordEl = doc.querySelector('.password-control');
    const isShopify = parseInt(global.common.getParam('shopify', ''), 10) === 1;
    const isShoppingCart = parseInt(global.common.getParam('shopping_cart', ''), 10) === 1;
    let queryParams = '';

    global.checkUserLoggedIn();

    if (isShopify || isShoppingCart) {
        queryParams = '?';
        if (isShopify) {
            queryParams = `${queryParams}shopify=1`
        }
        if (isShopify && isShoppingCart) {
            queryParams = `${queryParams}&`;
        }
        if (isShoppingCart){
            queryParams = `${queryParams}shopping_cart=1`;
        }
    }

    if (isShopify || isShoppingCart) {
        const loginLinkEl = doc.querySelector('.login-link');
        loginLinkEl.setAttribute('href', `${loginLinkEl.getAttribute('href')}${queryParams}`);
    }

    $(doc).on(
        'click',
        '.btn-show-password',
        global.togglePlainPassword
    );

    $(doc).on(
        'blur',
        '#login-form-username',
        bcUserCheck
    );


    if (form) {
        form.addEventListener('submit', doLogin);
    }

    if (isReturnUser === true) {
        const loginContainer = doc.querySelector('.container');

        if (loginContainer) {
            loginContainer.classList.add('return-user');
        }
    }

    // Displaying messages
    if (/m=/.test(loc.search)) {
        resetSuccessMessages();
        if (/m=reset/.test(loc.search)) {
            showSuccess('reset');
        } else if (/m=logout/.test(loc.search)) {
            showSuccess('logout');
        }
    }

    /**
     * Login to backend
     */
    function doLogin(event) {
        event.preventDefault();

        if (isPasswordVisible()) {
            return global.loginUtils.doRequest(
                getLoginData(),
                '/users/sign_in',
                handleSuccess,
                handleError,
                resetSuccessMessages);
        }

        doBcUserRequest();
    }


    function bcUserCheck() {
        if (isPasswordVisible()) {
            doBcUserRequest();
        }
    }


    function doBcUserRequest() {
        global.loginUtils.doRequest(
            {email: getEmail()},
            '/api/v2/users/bc_user',
            handleUserTypeSuccess,
            handleError,
            resetSuccessMessages
        );
    }


    function getEmail() {
        return doc.getElementById('login-form-username').value;
    }


    function isPasswordVisible() {
        return passwordEl.style.display === 'block';
    }


    function getLoginData() {
        const data = {
            user: {
                email: getEmail(),
                password: doc.getElementById('login-form-password').value,
            },
        };

        return data;
    }

    function handleUserTypeSuccess(responseStr, data) {
        try {
            const responseJson = JSON.parse(responseStr);

            if (responseJson.data.authenticity_token && responseJson.data.authenticity_token.length) {
                global.spinnerStop();
                return global.loginUtils.doBcLogin(responseJson.data.authenticity_token, data.email);
            }else {
                document.getElementById('reset-password').classList.remove('hide');
            }
        } catch (e) {
            if (window.Honeybadger) {
                window.Honeybadger.notify(e, 'bc-login-error');
            }
        }

        global.spinnerStop();

        if (isPasswordVisible() === false) {
            passwordEl.style.display = 'block';
            passwordEl.querySelector('#login-form-password').setAttribute('required', 'required');
        }
    }

    /**
     * Login handle success
     *
     * @param {string} responseStr
     */
    function handleSuccess(responseStr) {
        global.spinnerDone();

        setTimeout(() => {
            const cookieReferer = Cookies.get('__tlrref');
            if (typeof cookieReferer === 'string' && cookieReferer.length > 0) {
                Cookies.remove('__tlrref');
                loc.replace(cookieReferer);
            } else {
                const responseJson = JSON.parse(responseStr);
                const regexp = /login|reset_password|sign_up\.html/g;
                let redirectUrl = responseJson.redirect;

                if (regexp.test(redirectUrl)) {
                    redirectUrl = '/';
                }

                loc.replace(redirectUrl);
            }
        });
    }

    /**
     * Login handle error
     */
    function handleError() {
        global.spinnerStop();

        if (form) {
            form.classList.add('has-error');
        }
        resetPassword();
    }

    /**
     * Reset password
     */
    function resetPassword() {
        const passwordInput = doc.getElementById('login-form-password');
        if (passwordInput) {
            passwordInput.value = '';
        }
    }

    /**
     * Remove all success messages
     */
    function resetSuccessMessages() {
        if (form) {
            form.classList.remove('has-success');
        }

        doc.querySelectorAll('.success .show').forEach((item) =>
            item.classList.remove('show')
        );
    }

    /**
     * * Show success message
     *
     * @param name
     */
    function showSuccess(name) {
        const messageContainer = doc.querySelector(`.${name}`);
        if (messageContainer && form) {
            messageContainer.classList.add('show');
            form.classList.add('has-success');
        }
    }
})(window);
