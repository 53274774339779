(function module(global, $) {
    global.checkUserLoggedIn = checkUserLoggedIn;
    global.spinnerLoading = spinnerLoading;
    global.spinnerDone = spinnerDone;
    global.spinnerStop = spinnerStop;
    global.getLanguage = getLanguage;
    global.goToLoginPage = goToLoginPage;
    global.showError = showError;
    global.retrieveResponse = retrieveResponse;
    global.resetErrors = resetErrors;
    global.showFieldError = showFieldError;
    global.togglePlainPassword = togglePlainPassword;
    global.isLogged = isLogged;
    global.common = {
        getParam,
        doRequest,
        translate,
        getCookieDomain,
    };

    const doc = global.document;
    const $body = $(doc.body);
    const $form = $('.form');
    const $spinner = $('.form-spinner');

    /**
     * Check user is logged in
     */
    function checkUserLoggedIn() {
        // Check user is logged in
        setTimeout(() => {
            isLogged((isLoggedIn) => {
                if (isLoggedIn === true) {
                    spinnerLoading();
                    spinnerDone();
                    window.location = '/';
                }
            });
        });
    }

    /**
     * Redirect to login page
     */
    function goToLoginPage(replace = true) {
        const url = `/v3/${getLanguage()}/login.html`;

        if (replace === true) {
            global.location.replace(url);
        } else {
            global.location = url;
        }
    }

    /**
     * Make spinner loading
     */
    function spinnerLoading() {
        $('input[type=button]').prop('disabled', true);
        $body.addClass('loading');
    }

    /**
     * Remove spinner loading
     */
    function spinnerStop() {
        $('input[type=button]').prop('disabled', false);
        $body.removeClass('loading');
    }

    /**
     * Make spinner as DONE
     */
    function spinnerDone() {
        $('input[type=button]').prop('disabled', false);
        $spinner.addClass('done1');
    }

    /**
     * Return language
     */
    function getLanguage() {
        const lang = $('html').attr('lang');

        if (typeof lang !== 'string') {
            return 'en';
        }

        return lang.replace(/[^a-zA-Z]/, '');
    }

    /**
     * Check user is logged in
     */
    function isLogged(fnHandler) {
        const req = new XMLHttpRequest();
        req.open('GET', '/api/v2/users/show_current.json');

        // set headers
        req.setRequestHeader('Accept', 'application/json');
        req.setRequestHeader('Content-Type', 'application/json');

        req.onreadystatechange = () => {
            if (req.readyState === 4) {
                if (req.status === 200) {
                    fnHandler(true);
                } else {
                    fnHandler(false);
                }
            }
        };

        req.send();
    }

    /**
     * Show error
     *
     * @param {string[]|string} message
     * @param {string} text
     */
    function showError({message = [], text = ''} = {}) {
        $form.addClass('has-error');
        const messagesArr = prepareErrorMessages(message);

        if (messagesArr.length > 0) {
            messagesArr.forEach((messageItem) =>
                $(`.error .${messageItem}`).addClass('show')
            );
        } else {
            $('.error .general').addClass('show');
        }
    }

    /**
     * Hide all errors
     */
    function resetErrors() {
        $form.removeClass('has-error');
        $('.error .show').removeClass('show');
    }

    /**
     * Show field error
     *
     * @param {jQuery} $field
     * @param {string[]} message
     */
    function showFieldError({$field, message = []}) {
        const $fieldControl = $field.closest('.form-control');
        const messagesArr = prepareErrorMessages(message);

        if (messagesArr.length > 0) {
            $fieldControl.addClass('has-error');
            messagesArr.forEach((messageItem) =>
                $fieldControl
                    .find(`.form-control-error .${messageItem}`)
                    .addClass('show')
            );
        }
    }

    /**
     * Parse response text to JSON
     *
     * @param {string} responseText
     * @return {*}
     */
    function retrieveResponse(responseText) {
        try {
            return JSON.parse(responseText);
        } catch (e) {
            return null;
        }
    }

    /**
     * Prepare error messages
     *
     * @param {string|string[]} messages
     * @return {string[]}
     */
    function prepareErrorMessages(messages) {
        return typeof messages === 'string' && messages.length > 0
            ? [messages]
            : messages;
    }

    /**
     * Return parameter from query string
     *
     * @param {string} paramName
     * @param {*} defaultValue
     */
    function getParam(paramName, defaultValue = null) {
        const params = new URLSearchParams(global.location.search);
        return params.get(paramName) || defaultValue;
    }

    /**
     * Call request
     *
     * @param {string} url
     * @param {*} data
     * @param {string} method
     * @param {number} timeout
     * @param {Function} fnSuccess
     * @param {Function} fnError
     */
    function doRequest({
        url,
        data = {},
        method = 'POST',
        fnSuccess = () => {},
        fnError = () => {},
        onlyState = false,
    }) {
        const req = new XMLHttpRequest();

        req.open(method, url, true);
        req.setRequestHeader('Accept', 'application/json');
        req.setRequestHeader('Content-Type', 'application/json');
        req.onreadystatechange = () => {
            try {
                if (onlyState === true) {
                    return fnSuccess(req.status);
                }

                if (req.readyState === 4) {
                    if ([200,204].includes(req.status)) {
                        let jsonObj;

                        try {
                            jsonObj = JSON.parse(req.responseText);
                        } catch (e) {}

                        fnSuccess(jsonObj);
                    } else if (req.status === 422 || req.status === 400) {
                        fnError(JSON.parse(req.responseText));
                    } else {
                        fnError();
                    }
                }
            } catch (error) {
                fnError();
                throw error;
            }
        };

        req.send(JSON.stringify(data));
    }

    /**
     * Translate given key
     *
     * @param {string} keyName
     * @return {string}
     */
    function translate(keyName) {
        if (typeof global.__i18n === 'undefined') {
            return keyName;
        }

        return get(global.__i18n, keyName, keyName);
    }

    /**
     * Return domain for cookie depends from ENVIRONMENT
     *
     * @return {string}
     */
    function getCookieDomain() {
        const env = __dfw && __dfw.ENV ? __dfw.ENV : '';
        const isPreview = /^preview/.test(env);

        return isPreview === true
            ? window.location.hostname
            : '.datafeedwatch.com';
    }

    /**
     * Equivalent function to lodash "get"
     *
     * @param obj
     * @param path
     * @param def
     * @return {*}
     */
    function get(obj, path, def) {
        const everyFunc = (step) => !(step && (obj = obj[step]) === undefined);
        const fullPath = path
            .replace(/\[/g, '.')
            .replace(/]/g, '')
            .split('.')
            .filter(Boolean);

        return fullPath.every(everyFunc) ? obj : def;
    }

    // Polyfills //
    if (!Array.prototype.forEach) {
        Array.prototype.forEach = function (fun, thisp) {
            const len = this.length;
            if (typeof fun !== 'function') {
                throw new TypeError();
            }

            for (let i = 0; i < len; i += 1) {
                if (i in this) {
                    fun.call(thisp, this[i], i, this);
                }
            }
        };
    }

    /**
     * Toggle input plain/password
     *
     */
    function togglePlainPassword() {
        const $passwordControl = $('.password-control');
        const isPlain =
            $passwordControl.find('input[type=password]').length === 0;
        $passwordControl[isPlain ? 'removeClass' : 'addClass'](
            'password-visible'
        );
        $passwordControl.find('input').attr('type', isPlain === true ? 'password' : 'text');
    }

    if (!Array.prototype.find) {
        Array.prototype.find = function (predicate, thisArg) {
            if (this == null) {
                throw new TypeError(
                    'Array.prototype.find called on null or undefined'
                );
            }
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }
            const list = Object(this);
            const length = list.length >>> 0;
            let value;

            for (var i = 0; i < length; i++) {
                value = list[i];
                if (predicate.call(thisArg, value, i, list)) {
                    return value;
                }
            }
        };
    }

    if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype, 'findIndex', {
            value: function (predicate) {
                // 1. Let O be ? ToObject(this value).
                if (this == null) {
                    throw new TypeError('"this" ma wartość null lub undefined');
                }

                const o = Object(this);

                // 2. Niech len będzie ? ToLength(? Get(O, "length")).
                const len = o.length >>> 0;

                // 3. Jeśli IsCallable(predicate) jest fałszem, rzuć wyjątek TypeError.
                if (typeof predicate !== 'function') {
                    throw new TypeError('predykat musi być funkcją');
                }

                // 4. Jeśli thisArg został podany, niech T będzie thisArg; w przeciwnym wypadku, niech T będzie undefined.
                const thisArg = arguments[1];

                // 5. Let k be 0.
                let k = 0;

                // 6. Powtarzaj, dopóki k < len
                while (k < len) {
                    // a. Niech Pk będzie ! ToString(k).
                    // b. Niech kValue będzie ? Get(O, Pk).
                    // c. Niech testResult będzie ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                    // d. Jeśli testResult jest prawdą, zwróć k.
                    const kValue = o[k];
                    if (predicate.call(thisArg, kValue, k, o)) {
                        return k;
                    }
                    // e. Zwiększ wartość k o 1.
                    k++;
                }

                // 7. Return -1.
                return -1;
            },
        });
    }
})(window, window.jQuery);
