(function loginUtilsModule(global) {
    global.loginUtils = {
        doRequest,
        doBcLogin
    };

    function doRequest(data, url, successFn, errorFn, additionalFn = ()=>{}) {
        const req = new XMLHttpRequest();

        req.open('POST', url, true);
        // set headers
        req.setRequestHeader('Accept', 'application/json');
        req.setRequestHeader('Content-Type', 'application/json');
        req.onreadystatechange = () => {
            if (req.readyState === 4) {
                if (req.status === 200) {
                    successFn(req.responseText, data);
                } else {
                    errorFn();
                }
            }
        };

        global.spinnerLoading();
        req.send(JSON.stringify(data));
        additionalFn();
    }

    function doBcLogin(token,email) {
        const action = '/users/authenticate?provider=cart_openid';

        const postForm = document.createElement('FORM');
        postForm.setAttribute('action', action);
        postForm.style.display = 'none';
        postForm.setAttribute('method', 'POST');

        const tokenEl = document.createElement('INPUT');
        tokenEl.setAttribute('name', 'authenticity_token');
        tokenEl.setAttribute('value', token);
        postForm.appendChild(tokenEl);

        const emailEl = document.createElement('INPUT');
        emailEl.setAttribute('name', 'email');
        emailEl.setAttribute('value', email);
        postForm.appendChild(emailEl);

        document.body.appendChild(postForm);
        postForm.submit();
    }

})(window);
